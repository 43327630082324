import { Button, HStack, Input, Text, useNumberInput, useToast, VStack } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import WalletModal from '../../components/WalletModal';
import { ChainId } from '../../constants/networks';

import { useSneakers, useStaking } from '../../hooks/useContract';
import { supportedChainIds } from '../../utils/connectors';
import { switchToNetwork } from '../../utils/switchToNetwork';
import Countdown from '../../components/Countdown';
import { getProof, merkleTree } from '../../utils/merkle';

export default function MintActions({
  setAttemptingTxn,
  setTxHash,
  setErrorText,
  onOpen,
  setPendingText,
  whitelistMintDate,
  publicMintDate,
  isBeforeWhitelistMint,
  isBeforePublicMint,
}) {
  const toast = useToast();
  const { account, chainId, library } = useWeb3React();

  const [amount, setAmount] = useState(1);

  const stakingContract = useStaking();
  const sneakersContract = useSneakers();
  const [stakingStart, setStakingStart] = useState(new BigNumber(0));
  const [amountMinted, setAmountMinted] = useState(new BigNumber(0));

  const proof = useMemo(() => getProof(merkleTree, account), [account]);

  const proofPath = proof.proof.join(',') || [];

  const inMerkleProof = proofPath.length > 0;

  const fetchAmountMinted = useCallback(async () => {
    try {
      const res = await sneakersContract.methods.amountMinted(account).call();

      setAmountMinted(new BigNumber(res));
    } catch (err) {}
  }, [account, sneakersContract]);

  const fetchStakingStart = useCallback(async () => {
    try {
      const res = await stakingContract.methods.stakingStart(account).call();

      setStakingStart(new BigNumber(res));
    } catch (err) {}
  }, [account, stakingContract]);

  useEffect(() => {
    fetchStakingStart();
    fetchAmountMinted();
  }, [fetchStakingStart, fetchAmountMinted]);

  const handleChangeNetwork = async newChainId => {
    if (!library.provider) return;

    switchToNetwork({ provider: library.provider, chainId: newChainId });
  };

  const handleMint = useCallback(
    async (mintFn, value) => {
      try {
        setAttemptingTxn(true);
        setTxHash(null);
        setErrorText(null);
        setPendingText('Minting NFT');
        onOpen();
        const tx = await mintFn.send({ from: account, value });

        fetchAmountMinted();

        setTxHash(tx.transactionHash);
        setAttemptingTxn(false);
        toast({ title: 'Welcome to the MOOVCLUB 🎉', status: 'success' });
      } catch (err) {
        console.log(err);
        setErrorText('Transaction rejected');
      }
    },
    [
      toast,
      onOpen,
      account,
      setAttemptingTxn,
      setErrorText,
      setTxHash,
      setPendingText,
      fetchAmountMinted,
    ]
  );

  if (!account)
    return (
      <WalletModal w='100%'>
        <Button w='100%' variant='primary'>
          Connect wallet
        </Button>
      </WalletModal>
    );

  if (!supportedChainIds.includes(chainId))
    return (
      <Button w='100%' variant='primary' onClick={() => handleChangeNetwork(ChainId.MAINNET)}>
        Switch network
      </Button>
    );

  if (amountMinted.isGreaterThanOrEqualTo(10)) {
    return (
      <HStack fontWeight='medium' spacing='1' bg='brand.900' color='brand.500' p='3' w='100%'>
        <Text>You can't mint more with this address.</Text>
      </HStack>
    );
  }

  if (stakingStart.isGreaterThan(0) && isBeforePublicMint) {
    if (isBeforeWhitelistMint) {
      return (
        <HStack fontWeight='medium' spacing='1' bg='brand.900' color='brand.500' p='3' w='100%'>
          <Text>Whitelist Mint starts in</Text>
          <Countdown date={whitelistMintDate.toDate()} />
        </HStack>
      );
    }

    return (
      <VStack w='100%'>
        <HStack w='100%' justify='space-between'>
          <Text>Total Cost</Text>
          <Text>{(amount * 0.05).toFixed(2)} ETH</Text>
        </HStack>
        <AmountInput amount={amount} setAmount={setAmount} />
        <Button
          w='100%'
          variant='primary'
          onClick={() =>
            handleMint(
              sneakersContract.methods.mintStaking(amount),
              new BigNumber(amount)
                .multipliedBy(0.05)
                .multipliedBy(10 ** 18)
                .toFixed()
            )
          }
        >
          Mint NFT
        </Button>
      </VStack>
    );
  }

  if (inMerkleProof && isBeforePublicMint) {
    if (isBeforeWhitelistMint) {
      return (
        <HStack fontWeight='medium' spacing='1' bg='brand.900' color='brand.500' p='3' w='100%'>
          <Text>Whitelist Mint starts in</Text>
          <Countdown date={whitelistMintDate.toDate()} />
        </HStack>
      );
    }

    return (
      <VStack w='100%'>
        <HStack w='100%' justify='space-between'>
          <Text>Total Cost</Text>
          <Text>{(amount * 0.055).toFixed(3)} ETH</Text>
        </HStack>
        <AmountInput amount={amount} setAmount={setAmount} />
        <Button
          w='100%'
          variant='primary'
          onClick={() =>
            handleMint(
              sneakersContract.methods.mintMerkle(amount, proof.proof),
              new BigNumber(amount)
                .multipliedBy(0.055)
                .multipliedBy(10 ** 18)
                .toFixed()
            )
          }
        >
          Mint NFT
        </Button>
      </VStack>
    );
  }

  if (isBeforePublicMint) {
    return (
      <HStack fontWeight='medium' spacing='1' bg='brand.900' color='brand.500' p='3' w='100%'>
        <Text>Public Mint starts in</Text>
        <Countdown date={publicMintDate.toDate()} />
      </HStack>
    );
  }

  return (
    <VStack w='100%'>
      <HStack w='100%' justify='space-between'>
        <Text>Total Cost</Text>
        <Text>{(amount * 0.069).toFixed(3)} ETH</Text>
      </HStack>
      <AmountInput amount={amount} setAmount={setAmount} />
      <Button
        w='100%'
        variant='primary'
        onClick={() =>
          handleMint(
            sneakersContract.methods.mintPublic(amount),
            new BigNumber(amount)
              .multipliedBy(0.069)
              .multipliedBy(10 ** 18)
              .toFixed()
          )
        }
      >
        Mint NFT
      </Button>
    </VStack>
  );
}

function AmountInput({ amount, setAmount }) {
  const { getInputProps } = useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
    max: 10,
  });
  const input = getInputProps();

  return (
    <HStack w='100%'>
      <Button onClick={() => (amount > 1 ? setAmount(prev => prev - 1) : null)}>-</Button>
      <Input {...input} value={amount} onChange={value => setAmount(value)} w='100%' />
      <Button onClick={() => (amount < 10 ? setAmount(prev => prev + 1) : null)}>+</Button>
    </HStack>
  );
}
