import { Button, Text, useToast, VStack } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import WalletModal from '../../components/WalletModal';
import { ChainId } from '../../constants/networks';
import { useStaking } from '../../hooks/useContract';
import useCountdown from '../../hooks/useCountdown';
import { supportedChainIds } from '../../utils/connectors';
import { switchToNetwork } from '../../utils/switchToNetwork';

// const STAKING_AMOUNT = new BigNumber(60000).times(10 ** 18);
const STAKE_DURATION = new BigNumber('7776000');

export default function StakingActions({
  setAttemptingTxn,
  setTxHash,
  setErrorText,
  onOpen,
  setPendingText,
}) {
  const toast = useToast();
  const { account, chainId, library } = useWeb3React();
  //const tokenAddress = useMemo(() => addresses.token[chainId || ChainId.MAINNET], [chainId]);
  //const stakingAddress = useMemo(() => addresses.staking[chainId || ChainId.MAINNET], [chainId]);
  const stakingContract = useStaking();

  //const allowance = useAllowance(tokenAddress, stakingAddress);
  //const { onApprove } = useApprove(tokenAddress, stakingAddress);

  const [stakingStart, setStakingStart] = useState(new BigNumber(0));

  const fetchStakingStart = useCallback(async () => {
    try {
      const res = await stakingContract.methods.stakingStart(account).call();

      setStakingStart(new BigNumber(res));
    } catch (err) {}
  }, [account, stakingContract]);

  useEffect(() => {
    fetchStakingStart();
  }, [fetchStakingStart]);

  const handleChangeNetwork = async newChainId => {
    if (!library.provider) return;

    switchToNetwork({ provider: library.provider, chainId: newChainId });
  };

  /*
  const handleApprove = useCallback(async () => {
    try {
      setAttemptingTxn(true);
      setTxHash(null);
      setErrorText(null);
      setPendingText(`Approving MOOV for Staking`);
      onOpen();
      const tx = await onApprove();

      setTxHash(tx.transactionHash);
      setAttemptingTxn(false);
      toast({ title: 'You can now stake MOOV', status: 'success' });
    } catch (err) {
      console.log('err approving:', err);
      setErrorText('Transaction rejected');
    }
  }, [toast, setAttemptingTxn, setErrorText, setTxHash, setPendingText, onApprove, onOpen]);

  */

  const handleWithdraw = useCallback(async () => {
    try {
      setAttemptingTxn(true);
      setTxHash(null);
      setErrorText(null);
      setPendingText(`Withdrawing MOOV`);
      onOpen();
      const tx = await stakingContract.methods.unstake().send({ from: account });

      setTxHash(tx.transactionHash);
      setAttemptingTxn(false);
      setStakingStart(new BigNumber(Date.now()));
      toast({ title: 'Successfully withdrawn MOOV', status: 'success' });
    } catch (err) {
      console.log(err);
      setErrorText('Transaction rejected');
    }
  }, [
    toast,
    account,
    stakingContract.methods,
    setAttemptingTxn,
    setErrorText,
    setTxHash,
    setPendingText,
    onOpen,
  ]);

  if (!account)
    return (
      <WalletModal w='100%'>
        <Button w='100%' variant='primary'>
          Connect wallet
        </Button>
      </WalletModal>
    );

  if (!supportedChainIds.includes(chainId))
    return (
      <Button w='100%' variant='primary' onClick={() => handleChangeNetwork(ChainId.MAINNET)}>
        Switch network
      </Button>
    );

  if (stakingStart.isGreaterThan(1))
    return <Member stakingStart={stakingStart} handleWithdraw={handleWithdraw} />;

  return <Text>Staking no longer active</Text>;

  /*
  if (allowance.isLessThan(STAKING_AMOUNT))
    return (
      <Button variant='primary' w='100%' onClick={handleApprove}>
        Approve MOOV
      </Button>
    );

  return (
    <Button variant='primary' w='100%' onClick={handleStake}>
      Stake MOOV
    </Button>
  );
  */
}

function Member({
  stakingStart,
  handleWithdraw,
}: {
  stakingStart: BigNumber;
  handleWithdraw: any;
}) {
  const unixDate = new BigNumber(stakingStart).plus(STAKE_DURATION).toNumber();
  const unlockDate = new Date(unixDate * 1000);
  const now = moment();

  const {
    timeLeft: { days, hours, minutes, seconds },
  } = useCountdown(unlockDate);

  if (now.isAfter(unlockDate))
    return (
      <Button variant='primary' w='100%' onClick={handleWithdraw}>
        Withdraw MOOV
      </Button>
    );

  return (
    <VStack w='100%'>
      <Text color='green.300'>Welcome to the MOOVCLUB Whitelist 🎉</Text>
      <Text color='green.300'>
        Withdraw available in {days}d, {hours}h, {minutes}m, {seconds}s
      </Text>
    </VStack>
  );
}
