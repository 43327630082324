import { useMemo } from 'react';
import useWeb3 from './useWeb3';
import {
  getErc20Contract,
  getErc721Contract,
  getStakingContract,
  getSneakersContract
} from '../utils/contract-helper';
import { useWeb3React } from '@web3-react/core';

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = address => {
  const web3 = useWeb3();
  return useMemo(() => (address ? getErc20Contract(address, web3) : null), [address, web3]);
};

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = address => {
  const web3 = useWeb3();
  return useMemo(() => getErc721Contract(address, web3), [address, web3]);
};

export const useStaking = () => {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  return useMemo(() => getStakingContract(web3, chainId), [web3, chainId]);
};

export const useSneakers = () => {
  const web3 = useWeb3();
  const { chainId } = useWeb3React();
  return useMemo(() => getSneakersContract(web3, chainId), [web3, chainId]);
}