import erc20Abi from '../abis/erc20.json';
import erc721Abi from '../abis/erc721.json';
import stakingAbi from '../abis/staking.json';
import sneakersAbi from '../abis/sneakers.json';

import {
  getStakingAddress,
  getSneakersAddress,
} from './address-helper';

const getContract = (abi, address, web3) => {
  const _web3 = web3;
  return new _web3.eth.Contract(abi, address);
};

export const getErc20Contract = (address, web3) => {
  return getContract(erc20Abi, address, web3);
};

export const getErc721Contract = (address, web3) => {
  return getContract(erc721Abi, address, web3);
};

export const getStakingContract = (web3, chainId) => {
  return getContract(stakingAbi, getStakingAddress(chainId), web3);
};

export const getSneakersContract = (web3, chainId) => {
  return getContract(sneakersAbi, getSneakersAddress(chainId), web3);
}
