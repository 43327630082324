import { IconButton } from '@chakra-ui/button';
import { Button } from '@chakra-ui/button';
import { useClipboard } from '@chakra-ui/hooks';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader } from '@chakra-ui/modal';
import { Tooltip } from '@chakra-ui/tooltip';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';

export default function AccountContent({ onClose, ...props }) {
  const { account, deactivate } = useWeb3React();
  const { onCopy, hasCopied } = useClipboard(account, 500);

  const balance = useCurrencyBalance();
  const xedBalance = balance?.div(10 ** 18)?.toFixed(2) || 0;

  const shortAddress =
    account && `${account.substr(0, 10)}...${account.substr(account.length - 4, account.length)}`;

  return (
    <ModalContent bg='gray.700' {...props}>
      <ModalCloseButton />
      <ModalHeader>Wallet</ModalHeader>
      <ModalBody>
        <VStack w='100%' spacing='4' alignItems='start'>
          <HStack>
            <Tooltip label='Copy address' placement='top' bg='white'>
              <Button py='2' px='4' textTransform='initial' onClick={onCopy}>
                <HStack spacing='2'>
                  <Text color='white'>{hasCopied ? 'Address copied' : shortAddress}</Text>
                </HStack>
              </Button>
            </Tooltip>
            <Tooltip label='Disconnect wallet' placement='top' bg='white'>
              <IconButton
                icon={<FiLogOut />}
                onClick={() => {
                  localStorage?.removeItem('walletconnect');
                  deactivate();
                  onClose();
                }}
              />
            </Tooltip>
          </HStack>
        </VStack>
      </ModalBody>

      <VStack
        w='100%'
        mt='4'
        spacing='0'
        mb='-1'
        bg='gray.800'
        alignItems='start'
        borderBottomRadius='3xl'
      >
        <ModalHeader pb='0'>Balance</ModalHeader>
        <ModalBody pb='4' w='100%'>
          <HStack w='100%'>
            <HStack w='100%' fontSize='2xl'>
              <Text>{xedBalance}</Text>
              <Text>XED</Text>
            </HStack>
          </HStack>
        </ModalBody>
      </VStack>
    </ModalContent>
  );
}
