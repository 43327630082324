import React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import {
  Icon,
  Container,
  HStack,
  Heading,
  Box,
  Text,
  Image,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  VStack,
  Divider,
  Link,
  Center,
  Tag,
} from '@chakra-ui/react';
import { RiInstagramFill, RiTwitterFill } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';
import OpenSea from '../../assets/opensea.svg';
import Discord from '../../assets/discord.svg';

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Container maxW='container.xl' px='8' zIndex='999'>
      <HStack py='6' w='100%' justifyContent='space-between'>
        <HStack zIndex='3' as={RouterLink} to='/'>
          <Box boxSize='15px' mr='0.5' mb='2px' bg='brand.500' />
          <Heading as='h1' size='md' fontWeight='bold' mb='0' lineHeight='0'>
            MOOVCLUB
          </Heading>
        </HStack>

        <Box display={{ base: 'block', md: 'none' }} cursor='pointer' zIndex='3' onClick={onOpen}>
          <Icon boxSize='6' as={isOpen ? FiX : FiMenu} />
        </Box>

        <Drawer isOpen={isOpen} onClose={onClose} size='full' closeOnEsc>
          <DrawerOverlay zIndex='2' bg='rgba(0,0,0,0.95)' />
          <DrawerContent mt='80px' bg='none' border='0' overflowY='auto' h='100%'>
            <VStack
              w='100%'
              divider={<Divider bg='whiteAlpha.400' />}
              textTransform='uppercase'
              px='8'
              pt='6'
              fontFamily='Monument Extended'
              spacing='5'
              fontSize='lg'
            >
              <HStack
                as={RouterLink}
                to='/'
                boxShadow='none !important'
                outline='0 !important'
                w='100%'
                justifyContent='space-between'
                onClick={onClose}
                cursor='pointer'
              >
                <Text>MOOVCLUB</Text>
              </HStack>
              <HStack
                as={RouterLink}
                to='/staking'
                onClick={onClose}
                cursor='pointer'
                w='100%'
                justifyContent='space-between'
              >
                <Text>Staking</Text>
              </HStack>
              <HStack w='100%' justifyContent='space-between' color='#444' userSelect='none'>
                <Text>Careers</Text>
                <Tag size='sm'>Coming soon</Tag>
              </HStack>
              <HStack
                as={Link}
                href='https://twitter.com/moovclub'
                target='_blank'
                w='100%'
                justifyContent='space-between'
              >
                <Text>Twitter</Text>
                <Icon as={RiTwitterFill} boxSize='5' />
              </HStack>
              <HStack
                as={Link}
                href='https://instagram.com/moov.club'
                target='_blank'
                w='100%'
                justifyContent='space-between'
              >
                <Text>Instagram</Text>
                <Icon as={RiInstagramFill} boxSize='5' />
              </HStack>
              <HStack
                as={Link}
                href='https://discord.com/invite/ucYDnJPFNY'
                target='_blank'
                w='100%'
                justifyContent='space-between'
              >
                <Text>Discord</Text>
                <Image src={Discord} boxSize='5' />
              </HStack>
              <HStack
                w='100%'
                justifyContent='space-between'
                as={Link}
                href='https://opensea.io/collection/moovclub-sneakers'
                target='_blank'
              >
                <Text>OpenSea</Text>
                <Image src={OpenSea} boxSize='5' />
              </HStack>
            </VStack>
          </DrawerContent>
        </Drawer>

        <HStack spacing='8' display={{ base: 'none', md: 'flex' }}>
          <HStack spacing='6' alignItems='center'>
            <Center as={Link} href='https://twitter.com/moovclub' target='_blank'>
              <Icon as={RiTwitterFill} boxSize='24px' />
            </Center>
            <Center as={Link} href='https://instagram.com/moov.club' target='_blank'>
              <Icon as={RiInstagramFill} boxSize='24px' />
            </Center>
            <Center as={Link} href='https://discord.com/invite/ucYDnJPFNY' target='_blank'>
              <Image src={Discord} h='24px' />
            </Center>
            <Center
              as={Link}
              href='https://opensea.io/collection/moovclub-sneakers'
              target='_blank'
            >
              <Image src={OpenSea} h='24px' />
            </Center>
          </HStack>
          <Button
            as={Link}
            href='https://discord.com/invite/ucYDnJPFNY'
            target='_blank'
            variant='primary'
            size='sm'
            fontSize='xs'
            px='6'
            py='6'
          >
            Join Discord
          </Button>
        </HStack>
      </HStack>
    </Container>
  );
}

/*
function CustomLink({ to, children }: { children: React.ReactNode; to: string }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Text fontWeight={match ? 'bold' : 'regular'} as={RouterLink} to={to}>
      {children}
    </Text>
  );
}
*/
