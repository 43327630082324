import React from 'react';
import { VStack } from '@chakra-ui/react';
import Header from './Header';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <VStack
      w='100%'
      h='100vh'
      bgGradient='linear(to-b, black, gray.900)'
      spacing='0'
      overflowY='auto'
    >
      <Header />
      {children}
    </VStack>
  );
}
