import {
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useMemo, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal';
import useCurrencyBalance from '../../hooks/useCurrencyBalance';
import { getMoovAddress } from '../../utils/address-helper';
import StakingActions from './StakingActions';

export default function Staking() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { account, chainId, deactivate } = useWeb3React();

  const tokenAddress = useMemo(() => getMoovAddress(chainId), [chainId]);
  const token = useMemo(
    () => ({
      address: tokenAddress,
    }),
    [tokenAddress]
  );

  const balance = useCurrencyBalance(token);

  const [attemptingTxn, setAttemptingTxn] = useState();
  const [txHash, setTxHash] = useState();
  const [errorText, setErrorText] = useState();
  const [pendingText, setPendingText] = useState();

  return (
    <Container maxW='container.xl' px='8' h='calc(100vh - 80px)'>
      <VStack w='100%' h='100%' spacing='4' justifyContent='center' textAlign='center'>
        <TransactionConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          errorText={errorText}
          pendingText={pendingText}
          content={() => <Text>Staking</Text>}
        />
        <Heading size='lg'>MOOVCLUB Staking</Heading>
        <Text fontSize='lg'>
          5% of all royalties of the first 3 months to be distributed among stakers.
          <br />
          Plus, stakers get direct access to the whitelist with a special mint price of 0.05 ETH
        </Text>

        <VStack w='100%' maxW='420px' spacing='4' p='5' bg='gray.700' borderRadius='xl'>
          {account && (
            <VStack w='100%' alignItems='start'>
              <Text fontSize='xs' color='gray.300'>
                Wallet
              </Text>
              <HStack
                p='2'
                border='1px solid'
                borderColor='gray.600'
                borderRadius='sm'
                w='100%'
                justifyContent='space-between'
              >
                <Text userSelect='none'>{`${account.substr(0, 12)}[...]${account.substr(
                  -10
                )}`}</Text>
                <Icon as={FiLogOut} onClick={deactivate} cursor='pointer' />
              </HStack>
            </VStack>
          )}
          <VStack w='100%' alignItems='start'>
            <Text fontSize='xs' color='gray.300'>
              Blockchain
            </Text>
            <HStack border='1px solid' borderColor='gray.600' borderRadius='sm' w='100%' p='2'>
              <Text>Ethereum</Text>
            </HStack>
          </VStack>
          <VStack w='100%' alignItems='start'>
            <HStack w='100%' spacing='0' justifyContent='space-between'>
              <Text
                display={account ? { base: 'none', md: 'inline-block' } : 'inline-block'}
                fontSize='xs'
                color='gray.300'
              >
                Amount
              </Text>
              <Text fontSize='xs' color='gray.300' display={account ? 'inline-block' : 'none'}>
                You have {balance.div(10 ** 18).toFixed()} MOOV
              </Text>
            </HStack>
            <HStack
              justifyContent='space-between'
              border='1px solid'
              borderColor='gray.600'
              borderRadius='sm'
              w='100%'
              p='2'
            >
              <Text>60,000 MOOV</Text>
              <Button
                size='xs'
                px='2'
                py='1'
                borderRadius='md'
                variant='primary'
                as={Link}
                href='https://app.uniswap.org/#/swap?chain=mainnet&exactField=output&outputCurrency=0x24EC2Ca132abf8F6f8a6E24A1B97943e31f256a7&inputCurrency=ETH&exactAmount=60000'
                target='_blank'
              >
                Buy
              </Button>
            </HStack>
          </VStack>
          <VStack w='100%' alignItems='start'>
            <Text fontSize='xs' color='gray.300'>
              Duration
            </Text>
            <HStack border='1px solid' borderColor='gray.600' borderRadius='sm' w='100%' p='2'>
              <Text>90 days</Text>
            </HStack>
          </VStack>
          <StakingActions
            setAttemptingTxn={setAttemptingTxn}
            setPendingText={setPendingText}
            setErrorText={setErrorText}
            setTxHash={setTxHash}
            onOpen={onOpen}
          />
        </VStack>
      </VStack>
    </Container>
  );
}
