import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { Web3Provider } from '@ethersproject/providers';
import { ChainId } from '../constants/networks';

export const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export const supportedChainIds = isProduction ? 
[ChainId.MAINNET] : 
[ChainId.MAINNET, ChainId.MUMBAI];

export const defaultChainId = isProduction ? ChainId.MAINNET : ChainId.MUMBAI;

const WALLETCONNECT_BRIDGE_URL = 'https://bridge.walletconnect.org';

export const RPC = {
  [ChainId.MAINNET]: 'https://eth-mainnet.alchemyapi.io/v2/h2njDZzlfQ3qN6URZTlaT2jO8xC2eR8t',
  [ChainId.MUMBAI]: 'https://matic-mumbai.chainstacklabs.com/',
  
};

export const network = new NetworkConnector({
  urls: RPC,
  defaultChainId: ChainId.MAINNET,
});

export const injected = new InjectedConnector({
  urls: RPC,
  supportedChainIds: supportedChainIds,
});

let networkLibrary;
export function getNetworkLibrary() {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider));
}

export const getLibrary = provider => {
  return provider;
};

export const walletconnect = {
  [ChainId.MAINNET]: new WalletConnectConnector({
    rpc: {
      [ChainId.MAINNET]: RPC[ChainId.MAINNET],
    },
    bridge: WALLETCONNECT_BRIDGE_URL,
    qrcode: true,
    pollingInterval: 15000,
    supportedChainIds: [ChainId.MAINNET],
  }),
};
