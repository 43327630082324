import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { FiCircle } from 'react-icons/fi';

export default function Step({ isCompleted = false, isLast = false, isCurrent, children }) {
  return (
    <HStack
      alignItems='flex-start'
      spacing='3'
      w='100%'
      color={isCurrent ? 'brand.500' : 'whiteAlpha.500'}
      h={isLast ? '20' : '28'}
    >
      <VStack flex={1} h='100%' spacing='0'>
        <Icon
          as={FiCircle}
          color={isCurrent ? 'brand.500' : 'whiteAlpha.300'}
          fill={isCompleted ? 'brand.500' : 'transparent'}
          fontSize='sm'
        />
        {!isLast && <Box w='2px' h='100%' bg={isCurrent ? 'brand.500' : 'whiteAlpha.300'} />}
      </VStack>
      <VStack w='100%' spacing='0' alignItems='flex-start'>
        {children}
      </VStack>
    </HStack>
  );
}
