import {
  Text,
  VStack,
  HStack,
  Icon,
  useDisclosure,
  Box,
  Heading,
  Stack,
  Link,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal';
import MintActions from './MintActions';
import { RiWalletLine } from 'react-icons/ri';
import moment from 'moment';
import Countdown from '../../components/Countdown';
import Step from '../../components/Step';
import { useSneakers } from '../../hooks/useContract';

export default function MintCard({ ethPrice }) {
  const { account, deactivate } = useWeb3React();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [attemptingTxn, setAttemptingTxn] = useState();
  const [unmintedSupply, setUnmintedSupply] = useState(4444);
  const [txHash, setTxHash] = useState();
  const [errorText, setErrorText] = useState();
  const [pendingText, setPendingText] = useState();

  const now = moment();
  const publicMintDate = moment.unix(1654790400);
  const whitelistMintDate = moment.unix(1654704000);
  const revealDate = moment.unix(1654876800);

  const isBeforeWhitelistMint = now.isBefore(whitelistMintDate);
  const isBeforePublicMint = now.isBefore(publicMintDate);
  const isBeforeRevealDate = now.isBefore(revealDate);

  const sneakersContract = useSneakers();

  const fetchUnmintedSupply = useCallback(async () => {
    try {
      const res = await sneakersContract.methods.unmintedSupply().call();
      setUnmintedSupply(res);
    } catch (err) {
      console.log(err);
    }
  }, [sneakersContract.methods]);

  useEffect(() => {
    fetchUnmintedSupply();
  }, [fetchUnmintedSupply]);

  return (
    <Stack
      spacing='0'
      alignItems='start'
      bg='gray.800'
      borderRadius='md'
      border='1px solid'
      borderColor='brand.500'
      direction={{ base: 'column-reverse', lg: 'row-reverse' }}
    >
      <VStack w='100%' maxW='420px' spacing='4' p='0' h='100%' bg='gray.700' borderRadius='md'>
        <TransactionConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          errorText={errorText}
          pendingText={pendingText}
          content={() => <Text>Staking</Text>}
        />

        <video
          style={{ borderRadius: 8 }}
          src={process.env.PUBLIC_URL + '/rotating.mp4'}
          muted
          autoPlay
          loop
        />
        <VStack px='5' spacing='7' w='100%'>
          <HStack w='100%'>
            <VStack spacing='0.5' fontSize='sm' w='100%' align='start'>
              <Text>Sneakers Minted</Text>
              <Text color='gray.300'>{`${4444 - unmintedSupply}`} / 4444</Text>
            </VStack>
            <VStack spacing='0.5' fontSize='sm' w='100%' align='start'>
              <Text>Max Per Address</Text>
              <Text color='gray.300'>10</Text>
            </VStack>
          </HStack>
        </VStack>

        <VStack w='100%' spacing='4' p='5'>
          <MintActions
            setAttemptingTxn={setAttemptingTxn}
            setPendingText={setPendingText}
            setErrorText={setErrorText}
            setTxHash={setTxHash}
            onOpen={onOpen}
            publicMintDate={publicMintDate}
            whitelistMintDate={whitelistMintDate}
            isBeforePublicMint={isBeforePublicMint}
            isBeforeWhitelistMint={isBeforeWhitelistMint}
          />
          {account && (
            <VStack w='100%' alignItems='start'>
              <HStack p='3' bg='gray.800' borderRadius='sm' w='100%' justifyContent='space-between'>
                <HStack spacing='2'>
                  <Icon as={RiWalletLine} />
                  <Text userSelect='none'>{`${account.substr(0, 12)}[...]${account.substr(
                    -10
                  )}`}</Text>
                </HStack>
                <Icon as={FiLogOut} onClick={deactivate} cursor='pointer' />
              </HStack>
            </VStack>
          )}
        </VStack>
      </VStack>

      <VStack w='100%' maxW='480px' spacing='12' p='8' h='100%' bg='gray.800' borderRadius='md'>
        <HStack w='100%'>
          <Box boxSize={{ base: '12px', md: '15px' }} mr='0.5' mb='2px' bg='brand.500' />
          <Heading
            as='h1'
            fontSize={{ base: 'md', md: 'xl' }}
            fontWeight='medium'
            mb='0'
            lineHeight='0'
          >
            MOOVCLUB SNEAKERS
          </Heading>
        </HStack>

        <VStack spacing='0' justifyContent='center' w='100%' h='100%'>
          <Step isCurrent={!isBeforeWhitelistMint} isCompleted={!isBeforePublicMint}>
            <Row>
              <LeftCol>
                <Title>Stakers Mint</Title>
                {isBeforeWhitelistMint ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>Starts in</Text>
                    <Countdown date={whitelistMintDate.toDate()} />
                  </HStack>
                ) : isBeforePublicMint ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>Ends in</Text>
                    <Countdown date={publicMintDate.toDate()} />
                  </HStack>
                ) : (
                  <Text fontSize='sm' color='gray.300'>
                    Ended
                  </Text>
                )}
              </LeftCol>

              <RightCol>
                <Title>0.05 ETH</Title>
                <Description display={{ base: 'none', md: 'block' }}>{`$${(0.05 * ethPrice).toFixed(
                  2
                )}`}</Description>
              </RightCol>
            </Row>
          </Step>

          <Step isCurrent={!isBeforeWhitelistMint} isCompleted={!isBeforePublicMint}>
            <Row>
              <LeftCol>
                <Title>Whitelist Mint</Title>
                {isBeforeWhitelistMint ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>Starts in</Text>
                    <Countdown date={whitelistMintDate.toDate()} />
                  </HStack>
                ) : isBeforePublicMint ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>Ends in</Text>
                    <Countdown date={publicMintDate.toDate()} />
                  </HStack>
                ) : (
                  <Text fontSize='sm' color='gray.300'>
                    Ended
                  </Text>
                )}
              </LeftCol>

              <RightCol>
                <Title>0.055 ETH</Title>
                <Description display={{ base: 'none', md: 'block' }}>{`$${(
                  0.055 * ethPrice
                ).toFixed(2)}`}</Description>
              </RightCol>
            </Row>
          </Step>

          <Step isCurrent={!isBeforePublicMint} isCompleted={!isBeforeRevealDate}>
            <Row>
              <LeftCol>
                <Title>Public Mint</Title>
                {isBeforePublicMint ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>Starts in</Text>
                    <Countdown date={publicMintDate.toDate()} />
                  </HStack>
                ) : (
                  <Text color='brand.500' fontSize='sm' fontWeight='bold' textTransform='uppercase'>
                    Live
                  </Text>
                )}
              </LeftCol>

              <RightCol>
                <Title>0.069 ETH</Title>
                <Description display={{ base: 'none', md: 'block' }}>{`$${(
                  0.069 * ethPrice
                ).toFixed(2)}`}</Description>
              </RightCol>
            </Row>
          </Step>

          <Step isCompleted={!isBeforeRevealDate} isCurrent={!isBeforeRevealDate} isLast>
            <Row>
              <LeftCol>
                <Title>Reveal</Title>
                {isBeforeRevealDate ? (
                  <HStack fontSize='sm' color='gray.300'>
                    <Text>In</Text>
                    <Countdown date={revealDate.toDate()} />
                  </HStack>
                ) : (
                  <Text color='brand.500' fontSize='sm' fontWeight='bold' textTransform='uppercase'>
                    Revealed
                  </Text>
                )}
              </LeftCol>

              <RightCol display={{ base: 'none', md: 'flex' }}>
                <Title>OpenSea</Title>
                <Description
                  as={Link}
                  href='https://opensea.io/collection/moovclub-sneakers'
                  target='_blank'
                >
                  View collection
                </Description>
              </RightCol>
            </Row>
          </Step>
        </VStack>
      </VStack>
    </Stack>
  );
}

function Row({ children, ...rest }) {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w='100%'
      justify='space-between'
      mt='-1'
      {...rest}
    >
      {children}
    </Stack>
  );
}

function LeftCol({ children, ...rest }) {
  return (
    <VStack spacing='1' align='start' {...rest}>
      {children}
    </VStack>
  );
}

function RightCol({ children, ...rest }) {
  return (
    <Stack
      direction={{ base: 'row', md: 'column' }}
      spacing='1'
      align={{ base: 'start', md: 'end' }}
      {...rest}
    >
      {children}
    </Stack>
  );
}

function Title({ children, ...rest }) {
  return (
    <Text color='white' {...rest}>
      {children}
    </Text>
  );
}

function Description({ children, ...rest }) {
  return (
    <Text fontSize='sm' color='gray.300' {...rest}>
      {children}
    </Text>
  );
}
