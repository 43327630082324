import { HStack, Text } from '@chakra-ui/react';
import useCountdown from '../hooks/useCountdown';

export default function Countdown({ date }) {
  const {
    timeLeft: { days, hours, minutes, seconds },
    leadingZero,
  } = useCountdown(date);

  return (
    <HStack spacing='1' fontWeight='bold' color='white'>
      {days > 0 && <Text>{days}d</Text>}
      {(days > 0 || hours > 0) && <Text>{hours}h</Text>}
      <Text>{minutes}m</Text>
      <Text>{leadingZero(seconds)}s</Text>
    </HStack>
  );
}
