import Bsc from '../assets/networks/bsc-network.jpg';
import Mainnet from '../assets/networks/mainnet-network.jpg';
import Polygon from '../assets/networks/polygon-network.jpg';

export const ChainId = {
  MAINNET: 1,
  BSC: 56,
  POLYGON: 137,
  MUMBAI: 80001,
};

export const NetworkIcon = {
  [ChainId.MAINNET]: Mainnet,
  [ChainId.BSC]: Bsc,
  [ChainId.POLYGON]: Polygon,
  [ChainId.MUMBAI]: Polygon,
};

export const NetworkLabel = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.BSC]: 'BSC',
  [ChainId.POLYGON]: 'Polygon',
  [ChainId.MUMBAI]: 'Mumbai',
};

export const NetworkShortLabel = {
  [ChainId.BSC]: 'bsc',
  [ChainId.MAINNET]: 'eth',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.MUMBAI]: 'mumbai', // Staging only
};

export const NetworkExplorer = {
  [ChainId.MAINNET]: 'https://etherscan.com',
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.POLYGON]: 'https://polygonscan.com',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com',
};

export const NetworkParams = {
  [ChainId.MAINNET]: {
    chainId: `0x1`,
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://eth-mainnet.alchemyapi.io/v2/h2njDZzlfQ3qN6URZTlaT2jO8xC2eR8t'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
  [ChainId.BSC]: {
    chainId: `0x38`,
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [ChainId.POLYGON]: {
    chainId: `0x89`,
    chainName: 'Matic Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-mainnet.g.alchemy.com/v2/EzoIs9qOuagJEYCWJjy7cmonBvPQPQH4'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  [ChainId.MUMBAI]: {
    chainId: `0x13881`,
    chainName: 'Matic Testnet Mumbai',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'tMATIC',
      decimals: 18,
    },
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  },
};
