import addresses from '../constants/addresses';
import { ChainId } from '../constants/networks';

export const getAddress = (address, chainId) => {
  const mainNetChainId = ChainId.MAINNET;
  return address[chainId] ? address[chainId] : address[mainNetChainId];
};

export const getMoovAddress = chainId => {
  return getAddress(addresses.token, chainId);
};

export const getStakingAddress = chainId => {
  return getAddress(addresses.staking, chainId);
};

export const getSneakersAddress = chainId => {
  return getAddress(addresses.sneakers, chainId);
}
