import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import { whitelist } from './wl';

function generateMerkleTree(elements) {
  return new MerkleTree(elements, keccak256, {
    hashLeaves: true,
    sortPairs: true,
  });
}

export const merkleTree = generateMerkleTree(whitelist);


export function getRoot(merkleTree) {
  return merkleTree.getHexRoot();
}

export function getProof(merkleTree, element) {
  const leaf = keccak256(element).toString("hex");

  return {
    leaf,
    proof: merkleTree.getHexProof(leaf),
  };
}

export function test() {
  const root = getRoot(merkleTree);
  console.log(`Root: ${root}`);

  const proof = getProof(
    merkleTree,
    "0x0000000000000000000000000000000000000001"
  );
  console.log(`Leaf: 0x${proof.leaf}`);
  console.log(`Proof: [${proof.proof.join(",")}]`);
}
