import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, VStack } from '@chakra-ui/layout';
import { ModalContent, ModalOverlay, Modal } from '@chakra-ui/modal';

import MetamaskIcon from '../../assets/wallets/metamask.png';
import { SUPPORTED_WALLETS } from '../../constants/wallets';
import { isMobile } from 'react-device-detect';
import { injected } from '../../utils/connectors';
import AccountContent from '../Account/AccountContent';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import PendingView from './PendingView';
import Option from './Option';
import { ModalBody, ModalCloseButton, ModalHeader, Text, usePrevious, useToast } from '@chakra-ui/react';
import { setupNetwork } from '../../utils/wallet';
import { ChainId } from '../../constants/networks';

const WALLET_VIEWS = {
  ACCOUNT: 'account',
  OPTIONS: 'options',
  PENDING: 'pending',
};

export default function WalletModal({ isDisabled = false, children, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { active, error, account, connector, activate, chainId } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  const [pendingWallet, setPendingWallet] = useState();

  const [pendingError, setPendingError] = useState();

  const previousAccount = usePrevious(account);

  const toast = useToast();

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && isOpen) {
      onClose();
    }
  }, [account, previousAccount, onClose, isOpen]);

  // always reset to account view
  useEffect(() => {
    if (isOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [isOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      isOpen &&
      ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [setWalletView, active, error, connector, isOpen, activePrevious, connectorPrevious]);

  const tryActivation = async connector => {
    try {
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    const connectorToActivate = connector[chainId || ChainId.MAINNET] || connector;

    if (
      connectorToActivate instanceof WalletConnectConnector &&
      connectorToActivate.walletConnectProvider?.wc?.uri
    ) {
      connectorToActivate.walletConnectProvider = undefined;
    }

    connectorToActivate &&
      activate(connectorToActivate, undefined, true).catch(async error => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          console.log('has setup', hasSetup);
          if (hasSetup) {
            activate(connectorToActivate);
          } else {
            toast({ status: 'info', title: `Please make sure you're in Ethereum Mainnet`})
          }
        } else {
          setPendingError(false);
        }
      });
    } catch(err) {
      console.log('error connecting wallet', err);
    }
  };

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <Option
              onClick={() => {
                option.connector !== connector && !option.href && tryActivation(option.connector);
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconURL}
            />
          );
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header='Install Metamask'
                subheader={null}
                href={'https://metamask.io/'}
                icon={MetamaskIcon}
              />
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector);
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.iconURL}
          />
        )
      );
    });
  }

  function getModalContent() {
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return <AccountContent onClose={onClose} />;
    }

    return (
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {walletView !== WALLET_VIEWS.ACCOUNT ? (
            <Text cursor='pointer' onClick={() => setWalletView(WALLET_VIEWS.ACCOUNT)}>
              Back
            </Text>
          ) : (
            <Text>Connect to a wallet</Text>
          )}
        </ModalHeader>

        {walletView === WALLET_VIEWS.PENDING ? (
          <PendingView
            connector={pendingWallet}
            error={pendingError}
            setPendingError={setPendingError}
            tryActivation={tryActivation}
          />
        ) : (
          <>
            <ModalBody>
              <VStack w='100%' spacing='3'>
                {getOptions()}
              </VStack>
            </ModalBody>
          </>
        )}
        <Box mb='4' />
      </ModalContent>
    );
  }

  return (
    <>
      <Box onClick={isDisabled ? null : onOpen} {...rest}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size='sm' isCentered trapFocus={false}>
        <ModalOverlay />
        {getModalContent()}
      </Modal>
    </>
  );
}
