const addresses = {
  token: {
    1: '0x24EC2Ca132abf8F6f8a6E24A1B97943e31f256a7',
    80001: '0x7cbf7298D924080F53569e8b4a6Aaa4fA6cE9dE7',
  },
  staking: {
    1: '0x98f26c02e89a886f1445b166051be59bb5ac1733',
    80001: '0xb8afBcAC99e3d2cD4898d914Cf1c27FC1D89256D',
  },
  sneakers: {
    1: '0xbcE21A762deF23Ba095fBEa7CAf0e7d8a446694A',
    80001: '0xCa7cf783CC3be240B00420F837d6E00787d9179C'
  }
}

export default addresses;