import React from 'react';
import {
  Container,
  HStack,
  Box,
  Heading,
  VStack,
  Button,
  Text,
  Image,
  Center,
  Stack,
  Link,
  SliderTrack,
  SliderFilledTrack,
  Tooltip,
  Slider,
  SliderThumb,
  AspectRatio,
  Icon,
} from '@chakra-ui/react';

import snkrs9 from '../../assets/snkrs9.png';
import snkrsGif from '../../assets/SNEAKERS.gif';
import { RiInstagramFill, RiTwitterFill } from 'react-icons/ri';
import Discord from '../../assets/discord.svg';
import snkrsT from '../../assets/snkrsT.png';
import { FiExternalLink } from 'react-icons/fi';
import Famous from '../../assets/famous.png';
import snkrBlue from '../../assets/snkr_blue.png';
import dotmoovs from '../../assets/dotmoovs.svg';
import MintCard from './MintCard';
import useEthPrice from '../../hooks/useEthPrice';

export default function Home() {
  const ethPrice = useEthPrice();

  return (
    <>
      <Box py={{ base: '2', md: '6' }} />
      <Container maxW='container.xl' px='8'>
        <VStack w='100%' id='mint'>
          <MintCard ethPrice={ethPrice} />
        </VStack>
      </Container>

      <Box py={{ base: '12', lg: '12' }} />
      <HStack w='100%' alignItems='center' justifyContent='center' zIndex='5'>
        <Text fontSize='md'>A brand by</Text>
        <Image src={dotmoovs} w='32' />
      </HStack>
      <Box py='4' />

      <HStack w='100%' bg='white' zIndex='3' color='black'>
        <Center py='20' w='100%'>
          <Stack px='8' direction={{ base: 'column', md: 'row' }}>
            <Text fontSize='lg' lineHeight='1.8' fontFamily='Monument Extended' fontWeight='bold'>
              MOOVCLUB
            </Text>
            <Text fontSize='lg'>is bringing the physical and the digital worlds together.</Text>
          </Stack>
        </Center>
      </HStack>

      <video src={process.env.PUBLIC_URL + '/moovclub.mp4'} muted autoPlay loop />

      <Box py='10' />
      <Container maxW='container.lg' px='8' py='20'>
        <Stack
          alignItems='center'
          direction={{ base: 'column-reverse', lg: 'row' }}
          spacing={{ base: '2', lg: '16' }}
        >
          <Image src={snkrs9} w='420px' />

          <VStack w='100%' alignItems={{ base: 'center', lg: 'start' }} spacing='3'>
            <Heading size='xl'>Wearable NFTs</Heading>
            <Text fontSize='lg' w='100%' textAlign={{ base: 'center', lg: 'start' }}>
              <Text as='span' fontSize='lg' lineHeight='1.8' fontWeight='bold' color='brand.500'>
                MOOVCLUB SNEAKERS
              </Text>{' '}
              is a collection of 4,444 unique utility sneakers with mint price at 0.069 ETH.
              <br />
              It unlocks you Move2Earn competitions with crypto prizes.
            </Text>

            <Box py='3' />
            <Button
              variant='primary'
              as={Link}
              href='https://discord.com/invite/ucYDnJPFNY'
              target='_blank'
            >
              Join Discord
            </Button>
          </VStack>
        </Stack>
      </Container>

      <Box py={{ base: '4', lg: '8' }} />

      <Container maxW='container.lg' px='8' py={{ base: '4', lg: '20' }}>
        <Stack
          w='100%'
          justifyContent='space-around'
          alignItems='center'
          direction={{ base: 'column-reverse', lg: 'row-reverse' }}
          spacing={{ base: '6', lg: '16' }}
        >
          <AspectRatio ratio={9 / 20} width='320px'>
            <video
              style={{ borderRadius: '20px' }}
              src={process.env.PUBLIC_URL + '/moov.mp4'}
              muted
              autoPlay
              loop
            ></video>
          </AspectRatio>

          <VStack
            w='100%'
            alignItems={{ base: 'center', lg: 'start' }}
            textAlign={{ base: 'center', lg: 'start' }}
            spacing='3'
          >
            <Heading size='xl'>Dance2Earn</Heading>
            <Text fontSize='lg' w='100%' pb='1'>
              <Text as='span' fontSize='lg' lineHeight='1.8' fontWeight='bold' color='brand.500'>
                MOOVCLUB
              </Text>{' '}
              is brought to you by{' '}
              <Link
                href='https://dotmoovs.com'
                target='_blank'
                _hover={{ textDecoration: 'underline' }}
              >
                dotmoovs
              </Link>
              , a move2earn app with social-fi and metaverse sports games.{' '}
              <Text as='span' fontSize='lg' lineHeight='1.8' fontWeight='bold' color='brand.500'>
                SNEAKERS
              </Text>{' '}
              gives you exclusive access to this ecosystem.
            </Text>
            <Link color='brand.500' href='https://dotmoovs.com' target='_blank'>
              <HStack spacing='1'>
                <Text>dotmoovs website</Text>
                <Icon as={FiExternalLink} />
              </HStack>
            </Link>
          </VStack>
        </Stack>
      </Container>

      <Box py='8'>
        <Container maxW='container.lg' px='8' py='20' justifyContent='center'>
          <Stack
            w='100%'
            justifyContent='space-around'
            alignItems='center'
            direction={{ base: 'column-reverse', lg: 'row' }}
            spacing={{ base: '6', lg: '16' }}
          >
            <VStack
              alignItems='start'
              maxW='320px'
              spacing='4'
              borderRadius='2xl'
              bg='rgba(255,255,255,0.06)'
              p='4'
              shadow='lg'
            >
              <Image src={snkrsGif} w='100%' borderRadius='2xl' />
              <SliderThumbWithTooltip />
              <Button
                variant='primary'
                as={Link}
                href='https://discord.com/invite/ucYDnJPFNY'
                target='_blank'
                w='100%'
              >
                Lend NFT
              </Button>
            </VStack>

            <VStack alignItems={{ base: 'center', lg: 'start' }} spacing='3'>
              <Heading size='xl'>Lend & Earn</Heading>
              <Text fontSize='lg' w='100%' textAlign={{ base: 'center', lg: 'start' }}>
                Pick promising dancers and lend them your{' '}
                <Text as='span' fontSize='lg' lineHeight='1.8' fontWeight='bold' color='brand.500'>
                  SNEAKERS
                </Text>
                . <br />
                Earn crypto every time the dancer competes in dotmoovs.
              </Text>
            </VStack>
          </Stack>
        </Container>
      </Box>

      <Box py={{ base: '2', lg: '6' }} />

      <Container maxW='container.lg' px='8' py='20' justifyContent='center'>
        <Heading size='xl' textAlign={{ base: 'center', lg: 'start' }}>
          The Artists
        </Heading>

        <Box py={{ base: '4', lg: '10' }} />
        <Stack
          w='100%'
          justifyContent='space-around'
          alignItems='center'
          direction={{ base: 'column-reverse', lg: 'row-reverse' }}
          spacing={{ base: '6', lg: '16' }}
        >
          <Box w='100%' maxW='420px' borderRadius='xl'>
            <Image src={Famous} w='100%' />
          </Box>

          <VStack alignItems={{ base: 'center', lg: 'start' }}>
            <Text fontSize='3xl' fontWeight='600' color='brand.500'>
              Leandro Lopes
            </Text>
            <Text fontSize='lg' w='100%' textAlign={{ base: 'center', lg: 'start' }}>
              Having developed models for names such as Snoop Dogg, Floyd Mayweather and Neymar, we
              are proud to partner with 1 of the most prominent names in fashion industry. There
              will be an exclusive Leandro Lopes sneaker in this collection
            </Text>
          </VStack>
        </Stack>

        <Box py={{ base: '10', lg: '10' }} />
        <Stack
          w='100%'
          justifyContent='space-around'
          alignItems='center'
          direction={{ base: 'column-reverse', lg: 'row-reverse' }}
          spacing={{ base: '6', lg: '16' }}
        >
          <Box w='100%' maxW='420px' borderRadius='xl'>
            <Image src={snkrBlue} w='100%' />
          </Box>

          <VStack alignItems={{ base: 'center', lg: 'start' }}>
            <Text fontSize='3xl' fontWeight='600' color='brand.500'>
              Hugo Melo
            </Text>
            <Text fontSize='lg' w='100%' textAlign={{ base: 'center', lg: 'start' }}>
              He has been the lead-designer for the footwear of Le Mans race drivers, amongst other
              driving Grand Prix drivers. Hugo has also developed and lead the design of several
              footwear collections for brands such as Piloti, Coq-luxe, Flash Fashion, Prozis,
              Zouri, No Brand and his own IN Pair.
            </Text>
          </VStack>
        </Stack>
      </Container>

      <Box py={{ base: '2', lg: '20' }} />

      <Container maxW='container.lg' px='8'>
        <VStack spacing={{ base: '4', lg: '12' }}>
          <Image src={snkrsT} />
          <VStack spacing='4' alignItems='center' textAlign='center'>
            <Heading as='h2' fontSize={{ base: 'xl', md: '4xl' }}>
              Join our Community
            </Heading>
            <Text pb='4'>Help us build the next generation of utility NFTs</Text>
            <HStack spacing='6' alignItems='center'>
              <Center as={Link} href='https://twitter.com/moovclub' target='_blank'>
                <Icon as={RiTwitterFill} boxSize='24px' />
              </Center>
              <Center as={Link} href='https://instagram.com/moov.club' target='_blank'>
                <Icon as={RiInstagramFill} boxSize='24px' />
              </Center>
              <Center as={Link} href='https://discord.com/invite/ucYDnJPFNY' target='_blank'>
                <Image src={Discord} h='24px' />
              </Center>
            </HStack>
          </VStack>
        </VStack>
        <Box py='6' />
      </Container>
    </>
  );
}

function SliderThumbWithTooltip() {
  const [sliderValue, setSliderValue] = React.useState(50);
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <VStack w='100%'>
      <HStack fontSize='sm' w='100%' justifyContent='space-between'>
        <Text>Revenue Share</Text>
        <Text>{sliderValue}%</Text>
      </HStack>
      <Slider
        id='slider'
        value={sliderValue}
        defaultValue={50}
        min={0}
        max={100}
        colorScheme='brand'
        size='md'
        onChange={v => setSliderValue(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack>
          <SliderFilledTrack bgColor='brand.500' transition='all 0.2s ease-out' />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg='brand.500'
          color='black'
          placement='top'
          isOpen={showTooltip}
          label={`${sliderValue}%`}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    </VStack>
  );
}
