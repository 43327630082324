import { ChainId } from '../constants/networks';

const ETHERSCAN_PREFIXES = {
  [ChainId.MAINNET]: '',
};

export const ExplorerDataType = {
  TRANSACTION: 'transaction',
  TOKEN: 'token',
  ADDRESS: 'address',
  BLOCK: 'block',
};

export function getExplorerLink(chainId, type, data) {
  let prefix = '';

  switch (chainId) {
    case ChainId.BSC:
      prefix = 'https://bscscan.com';
      break;
    case ChainId.POLYGON:
      prefix = 'https://polygonscan.com';
      break;
    case ChainId.MUMBAI:
      prefix = 'https://mumbai.polygonscan.com';
      break;
    default:
      prefix = `https://${ETHERSCAN_PREFIXES[chainId] ?? ''}etherscan.io`;
  }

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
