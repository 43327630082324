import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import theme from './theme';
import Staking from './pages/Staking';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from './utils/connectors';
import { RefreshContextProvider } from './contexts/RefreshContext';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <RefreshContextProvider>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/'>
                <Route index element={<Home />} />
                <Route path='staking' element={<Staking />} />
              </Route>
            </Routes>
          </Layout>
        </BrowserRouter>
      </RefreshContextProvider>
    </Web3ReactProvider>
  </ChakraProvider>
);
