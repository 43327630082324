import { extendTheme } from '@chakra-ui/react';

const theme = {
  fonts: {
    heading: 'Monument Extended, sans-serif',
    body: 'Surt, sans-serif',
  },
  colors: {
    brand: {
      50: '#ffffe6',
      100: '#fffecc',
      200: '#fffe99',
      300: '#fffd66',
      400: '#fffd33',
      500: '#fffc00',
      600: '#ccca00',
      700: '#999700',
      800: '#666500',
      900: '#333200',
    },
    gray: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#c0c0c0',
      400: '#636366',
      500: '#48484a',
      600: '#3a3a3c',
      700: '#1d2021',
      800: '#151718',
      900: '#0e0e0f',
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  components: {
    Link: {
      baseStyle: {
        _hover: { textDecoration: 'none' },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          py: '1',
          borderRadius: 'none',
          bg: 'gray.900',
        },
        header: {
          fontSize: 'md',
          borderBottom: '1px solid',
          borderColor: 'gray.700',
          py: '3',
        },
        body: {
          py: '5',
        },
        footer: {
          py: '3',
          borderTop: '1px solid',
          borderColor: 'gray.700',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg',
        textTransform: 'uppercase',
        fontWeight: 'regular',
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        primary: {
          bg: 'brand.500',
          color: 'black',
          fontFamily: 'Surt, sans-serif',
          fontWeight: '500',
          fontSize: 'xs',
          px: '8',
          py: '6',
          _hover: {
            bg: 'brand.500',
            boxShadow: 'inset 0 -4em 0 0 white',
            transition: 'all .7s cubic-bezier(.19,1,.22,1)',
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
