import React from 'react';
import { Box, HStack, Image, VStack, Text } from '@chakra-ui/react';
import LinkWrapper from '../LinkWrapper';

export default function Option({
  href = null,
  to = null,
  clickable = true,
  size,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id,
  ...props
}) {
  const content = (
    <HStack
      key={id}
      w='100%'
      py='2'
      px='4'
      bg='gray.700'
      border='1px solid'
      borderColor='transparent'
      borderRadius='sm'
      cursor={clickable ? 'pointer' : 'default'}
      _hover={{ borderColor: clickable ? 'gray.400' : 'transparent' }}
      justifyContent='space-between'
      onClick={clickable && !active ? onClick : null}
      {...props}
    >
      <HStack spacing='2' p='1'>
        <VStack alignItems='start' w='100%'>
          <HStack spacing='2'>
            {active && <Box boxSize='6px' bg='green.300' borderRadius='full' />}
            <Text fontSize='lg'> {header}</Text>
          </HStack>
          {subheader && (
            <Text fontSize='sm' color='gray.200'>
              {subheader}
            </Text>
          )}
        </VStack>
      </HStack>
      <Image src={icon} alt='Icon' h='6' />
    </HStack>
  );

  if (href) {
    return (
      <LinkWrapper href={href} target='_blank' rel="noreferer noopener" w='100%'>
        {content}
      </LinkWrapper>
    );
  }

  if (to) {
    return <LinkWrapper to={to} w='100%'>{content}</LinkWrapper>;
  }

  return content;
}
