import React from 'react';
import { Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link, NavLink } from 'react-router-dom';

const LinkWrapper = React.forwardRef((props, ref) => {
  const { to, navTo, href, isDisabled, children, ...rest } = props;

  if (isDisabled)
    return (
      <Box w='100%' opacity='0.7' cursor='no-drop'>
        {children}
      </Box>
    );

  if (to)
    return (
      <Link to={to} ref={ref} {...rest}>
        {children}
      </Link>
    );

  if (navTo)
    return (
      <NavLink to={navTo} innerRef={ref} {...rest}>
        {children}
      </NavLink>
    );

  if (href)
    return (
      <ChakraLink
        href={href}
        ref={ref}
        target='_blank'
        rel="noreferer noopener"
        _hover={{ textDecoration: 'none' }}
        {...rest}
      >
        {children}
      </ChakraLink>
    );

  return <Box {...rest}>{children}</Box>;
});

export default LinkWrapper;
