
import { useCallback, useEffect, useState } from 'react';
import { API_COINGECKO } from '../utils/swap';

export default function useEthPrice(updateSeconds = 60) {
  const [price, setPrice] = useState(0);

  const fetchPrice = useCallback(async () => {
    try {
      const res = await fetch(
        `${API_COINGECKO}/simple/price?ids=ethereum&vs_currencies=usd`
      );

      const data = await res.json();

      setPrice(data?.ethereum?.usd || 0);
    } catch (err) { }
  }, [])

  useEffect(() => {
    fetchPrice();

    const id = setInterval(() => fetchPrice(), updateSeconds * 1000)

    return () => clearInterval(id);
  })

  return price;
}
